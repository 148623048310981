import { css, html } from 'lit';
import { ReportFilingFormSection } from '../mixin';
import './shared/report-filing-details-form-section';
import '../../../pli/pli-textarea';
import { styles } from 'pli/styles';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { customElement } from 'lit/decorators.js';

@customElement('report-filing-details-actions')
class ReportFilingDetailsActions extends ReportFilingFormSection {
    static styles = [styles.grid, css``];
    render() {
        const { payload, setPayload, renderErrorMessageForField, getStateForField } = this;
        return html` <report-filings-details-form-section>
            <div class="grid-vertical gap-05">
                <label>
                    <pli-text><strong>Actions taken or planned * </strong></pli-text>
                    <pli-text as="p"
                        >Write what action(s) you have taken after identifying the suspicion. MAX 4000 characters
                    </pli-text>
                </label>
                <pli-textarea
                    value="${payload?.actions}"
                    state="${getStateForField('actions')}"
                    @change="${(event: PliInputChangeEvent) =>
                        setPayload({
                            actions: event.detail.value,
                        })}"
                ></pli-textarea>
                ${renderErrorMessageForField('actions')}
            </div>
        </report-filings-details-form-section>`;
    }
}
