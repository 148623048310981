import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { styles } from 'pli/styles';

import '../../pli/pli-button';
import '../../pli/pli-icon';
import '../../pli/pli-card';

import { consume, provide } from '@lit/context';
import { HttpClient, clientContext } from 'context/client-context';
import { Task } from '@lit/task';
import { getFormattedLocalTime } from 'utils/datetime-formatter';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { UserModel } from 'user-model';
import { downloadContent } from 'utils/download-content';
import { Issue, ValidationController } from 'controllers/validation-controller';
import {
    ReportFilingsDetailReponse,
    Payload,
    ReportFilingsDetailPayload,
    suspiciousIndicatorsEnum,
    riskIndicatorsEnum,
    localPayloadSchema,
    reportFilingStatus,
} from './schema';
import db from 'just-debounce';
import { dateFormats } from 'utils/date';
import { DialogService } from 'aurelia-dialog';
import { dialogServiceContext } from 'context/dialog-service-context';
import { DownloadReportDialog } from 'report-filing/details/download-report-dialog/download-report-dialog';
import { DeleteReportDialog } from 'report-filing/details/delete-report-dialog/delete-report-dialog';
import { Router, routerContext } from 'context/router-context';
import { ReopenReportDialog } from 'report-filing/details/reopen-report-dialog/reopen-report-dialog';
import '../../pli/pli-drag-drop';
import '../../pli/pli-status-label';
import {
    reportFilingPayloadContext,
    reportFilingPayloadMutationContext,
} from './context/report-filings-payload-context';
import { reportFilingSavedDateContext } from './context/report-filings-saved-context';
import { reportFilingIssuesContext } from './context/report-filings-issues-context';

import './components/shared/report-filing-details-form-section';
import './components/report-filing-details-button-group';
import './components/report-filing-details-general-information';
import './components/report-filing-details-location';
import './components/report-filing-details-reason';
import './components/report-filing-details-actions';
import './components/report-filing-details-activity';
import './components/report-filing-details-indicators';
import './components/report-filing-details-documents';
import './components/report-filing-details-agent-information';

@customElement('report-filings-details')
class ReportFilingsDetails extends LitElement {
    static styles = [
        styles.grid,
        styles.flex,
        styles.base,
        styles.position,
        styles.list,
        styles.form,
        css`
            :host {
                --button-group-top: var(--size-2-5);
                --saved-date-wrapper-negative-offset: calc(var(--button-group-top) * -1);
            }
            fieldset {
                appearance: none;
                border: 0;
            }

            .button-group {
                top: var(--button-group-top);
                align-self: flex-start;
            }

            @keyframes appear {
                from {
                    opacity: 0;
                    transform: translateY(var(--size-1));
                }

                to {
                    opacity: 1;
                    transform: none;
                }
            }

            .error {
                display: block;
                margin-top: var(--size-0-5);
            }
        `,
    ];

    @property()
    reportfilingid: string;

    @provide({ context: reportFilingPayloadContext })
    payload: ReportFilingsDetailPayload;

    @provide({ context: reportFilingSavedDateContext })
    savedDate: string;

    @provide({ context: reportFilingPayloadMutationContext })
    payloadMutation: (payload: Payload) => void;

    @provide({ context: reportFilingIssuesContext })
    issues: Issue[];

    @consume({ context: clientContext })
    public client: HttpClient;

    @consume({ context: dialogServiceContext })
    public dialogService: DialogService;

    @consume({ context: currentUserContext })
    public currentUser: CurrentUser;

    @consume({ context: routerContext })
    public router: Router;

    @state()
    _profile: UserModel = new UserModel();

    @state()
    _savedDate: string | null = null;

    _schema = localPayloadSchema;

    _validationController = new ValidationController(this, this._schema);

    _task = new Task(this, {
        task: async ([]) => {
            const response: any = await this.client.get(`report-filings/${this.reportfilingid}`);
            const data = (await response.json()) as ReportFilingsDetailReponse;
            this.setInitialValue(data);
        },
        args: () => [],
    });

    async connectedCallback() {
        super.connectedCallback();
        this._profile = await this.currentUser.get();
        this.payloadMutation = this._handlePayloadChange;
    }

    _saveWithDebounce = db(async () => {
        const mapped: ReportFilingsDetailReponse = {
            ...this.payload,
            reportIndicatorTypes: [...this.payload.suspiciousIndicators, ...this.payload.riskIndicators] as never,
        };
        const payload = JSON.stringify(mapped);
        const response = await this.client.put(`report-filings/${this.reportfilingid}`, payload);

        if (response.ok) {
            this.displaySavedNotification();
        }
    }, 1000);

    private displaySavedNotification() {
        this.savedDate = getFormattedLocalTime(new Date(), dateFormats.time);

        window.setTimeout(() => {
            this.savedDate = null;
        }, 10000);
    }

    get isFormEnabled() {
        return this.payload?.status === reportFilingStatus.Enum.InProgress;
    }
    get isValid() {
        return !this._validationController.issues.length;
    }

    _handlePayloadChange = (payload: Payload) => {
        this.payload = { ...this.payload, ...payload };
        this._validationController.parsePartial(payload);
        this.issues = [...this._validationController.issues];

        if (!this.isValid) {
            return;
        }

        this._saveWithDebounce();
    };

    setInitialValue(data: ReportFilingsDetailReponse) {
        const suspiciousIndicators = data.reportIndicatorTypes.filter((type) =>
            suspiciousIndicatorsEnum.options.includes(type),
        );
        const riskIndicators = data.reportIndicatorTypes.filter((type) => riskIndicatorsEnum.options.includes(type));
        this.payload = {
            ...data,
            suspiciousIndicators,
            riskIndicators,
        };
    }

    /* Button actions */

    downloadReport = async () => {
        const { isValid } = this._validationController.parse(this.payload);
        this.issues = [...this._validationController.issues];
        if (!isValid) {
            return;
        }

        this.dialogService
            .open({
                viewModel: DownloadReportDialog,
                model: null,
                lock: false,
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    this._handlePayloadChange({ status: reportFilingStatus.Enum.Reported });
                }
            });

        const response = await this.client.get(`report-filings/${this.reportfilingid}/documents/zip`);
        const content = await response.blob();
        const name = `report-filing-${this.reportfilingid}.zip`;

        downloadContent(content, name);
    };

    closeReport = async (): Promise<void> => {
        const { isValid } = this._validationController.parse(this.payload);
        this.issues = [...this._validationController.issues];
        if (!isValid) {
            return;
        }
        this._handlePayloadChange({ status: reportFilingStatus.enum.Reported });
    };

    reopenReport = async (): Promise<void> => {
        this.dialogService
            .open({ viewModel: ReopenReportDialog, model: null, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    this._handlePayloadChange({ status: reportFilingStatus.enum.InProgress });
                }
            });
    };

    deleteReport = async (): Promise<void> => {
        const model = { id: this.reportfilingid };
        this.dialogService
            .open({ viewModel: DeleteReportDialog, model: model, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    this.router.navigateToRoute('report-filing');
                }
            });
    };

    render() {
        const { downloadReport, reopenReport, closeReport, deleteReport, payload, isFormEnabled } = this;
        
        return html`
            <div class="grid-vertical gap-1">
                <pli-button variant="text" as="a" href="/report-filing">
                    <pli-icon name="arrow-left-circle" slot="icon-left"></pli-icon>
                    Back to reports
                </pli-button>
                <pli-text as="h1" variant="h1">Report</pli-text>
                <pli-text as="p">
                    Report created from case:
                    <a href="/customers/${payload?.customerId}/cases/${payload?.case.caseId}/#investigations">${payload?.case.name}</a>
                </pli-text>
                <div class="grid gap-1 relative">
                    <div class="col-span-10">
                        <form class="grid-vertical gap-2" .inert="${!isFormEnabled}">
                            <pli-card>
                                <div class="grid-vertical gap-4">
                                    <report-filing-details-general-information></report-filing-details-general-information>
                                    <report-filing-details-agent-information></report-filing-details-agent-information>
                                    <report-filing-details-location></report-filing-details-location>
                                    <report-filing-details-reason></report-filing-details-reason>
                                    <report-filing-details-actions></report-filing-details-actions>
                                    <report-filing-details-activity></report-filing-details-activity>
                                </div>
                            </pli-card>
                            <pli-card>
                                <report-filing-details-indicators></report-filing-details-indicators>
                            </pli-card>
                            <pli-card>
                                <report-filing-details-documents></report-filing-details-documents>
                            </pli-card>
                        </form>
                    </div>
                    <div class="col-span-2 button-group sticky">
                        <report-filing-details-button-group
                            .onDownload="${downloadReport}"
                            .onClose="${closeReport}"
                            .onReopen="${reopenReport}"
                            .onDelete="${deleteReport}"
                        ></report-filing-details-button-group>
                    </div>
                </div>
            </div>
        `;
    }
}
