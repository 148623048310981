import { HttpClient, json } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { ErrorService } from 'error/error-service';
import { IPagedController } from 'components/pager/pager';
import { dateFormats } from 'utils/date';
import { ReportFilingStatus } from 'report-filing/report-filing-status';
import { StorageHandler } from 'storage-handler';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@autoinject
export class ReportFilingsList implements IPagedController {
    httpClient: HttpClient;
    reports: ReportFiling[] = [];
    page: number = 1;
    of: number;
    total: number;
    sortOrderKey: string = 'report-filings-list-order';
    sortOrderField: string;
    sortOrderValue: string;
    errorService: ErrorService;

    constructor(
        httpClient: HttpClient,
        errorService: ErrorService,
        private router: Router,
    ) {
        this.httpClient = httpClient;
        this.errorService = errorService;
    }

    async activate() {
        await this.loadPage();
    }

    async changePage(page: number) {
        await this.loadPage(page);
    }

    async loadPage(page: number = 1) {
        const response = await this.httpClient.get(
            `report-filings?page=${encodeURIComponent(`{ "no": ${page}}`)}${this.buildQuery()}`,
        );
        const json = await response.json();

        this.reports = <ReportFiling[]>json.list;
        this.of = Math.ceil(json.total / 15);
        this.total = json.total;
    }

    buildQuery(): string {
        let result: string[] = [];

        const storedItem = StorageHandler.getFromStorage(this.sortOrderKey);
        if (storedItem !== null) {
            this.sortOrderField = storedItem.field;
            this.sortOrderValue = storedItem.sortOrder;
            result.push(
                `&sort=${encodeURIComponent(`{ "field": "${storedItem.field}", "order": "${storedItem.sortOrder}"}`)}`,
            );
        }

        return result.join('');
    }

    getFormattedLocalTime(dateTimeUtc: Date): string {
        return dayjs(dateTimeUtc).utc().local().format(dateFormats.date);
    }

    async refresh() {
        await this.loadPage(this.page);
    }

    async sort(field: string, order: string) {
        StorageHandler.saveToStorage(this.sortOrderKey, {
            field: field,
            sortOrder: order,
        });
        await this.refresh();
    }
}

class ReportFiling {
    reportFilingId: string;
    filingName: string;
    reportCode: string;
    createdUtc: Date;
    partyFirstName: string;
    partyLastName: string;
    status: ReportFilingStatus;
    assigned: Assigned[];
}

class Assigned {
    userId: string;
    firstName: string;
    lastName: string;
    fullName: string;
}
