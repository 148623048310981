import { bindable } from 'aurelia-framework';

export class ReportFilingsStatusMarker {
    @bindable status: string;

    color: string;
    text: string;

    statuses = {
        InProgress: {
            color: 'in-progress',
            text: 'In Progress',
        },
        Reported: {
            color: 'reported',
            text: 'Reported',
        },
        Cancelled: {
            color: 'cancelled',
            text: 'Cancelled',
        },
    };

    statusChanged(value: string): void {
        if (value === undefined || value === null) {
            return;
        }

        let status = this.statuses[value];

        if (status) {
            this.color = status.color;
            this.text = status.text;
        }
    }
}
