import { css } from 'lit';

export const positionStyles = css`
    .relative {
        position: relative;
    }
    .absolute {
        position: absolute;
    }
    .sticky {
        position: sticky;
    }
`;
