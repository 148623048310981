import { Router } from 'aurelia-router';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { TimelineItem } from 'components/timeline/timeline';
import { DialogService } from 'aurelia-dialog';
import { AssignService } from 'services/assign-dialog/assign-service';
import { UserModel } from 'user-model';
import { CurrentUser } from 'components/current-user/current-user';
import { DownloadReportDialog } from './download-report-dialog/download-report-dialog';
import { ReopenReportDialog } from './reopen-report-dialog/reopen-report-dialog';
import { DeleteReportDialog, Model as DeleteReportModel } from './delete-report-dialog/delete-report-dialog';
import { dateFormats } from 'utils/date';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ReportFiling, Indicator } from './report-filing';
import { ValidationRules, ValidationControllerFactory, ValidationController } from 'aurelia-validation';
import { ReportFilingStatus } from 'report-filing/report-filing-status';
import db from 'just-debounce';
import { cleanParamsObject } from 'utils/params';

dayjs.extend(utc);

@autoinject
export class ReportFilingDetails {
    reportFilingId: string;
    currentUser: CurrentUser;
    httpClient: HttpClient;
    customerId: string;
    json: string;
    timelineItems: TimelineItem[];
    dialogService: DialogService;
    router: Router;
    assignService: AssignService;
    users: UserModel[];
    controller: ValidationController;
    isDownloading: boolean = false;
    model: ReportFiling;
    savedDate: string = '';
    showSaved: boolean = false;
    showDownloadError: boolean = false;
    showCloseReportError: boolean = false;
    disableReport: boolean = true;
    saveWithDebounce = db(async function () {
        this.showDownloadError = false;
        this.showCloseReportError = false;
        await this.save();
    }, 1000);

    constructor(
        httpClient: HttpClient,
        dialogService: DialogService,
        router: Router,
        assignService: AssignService,
        currentUser: CurrentUser,
        controllerFactory: ValidationControllerFactory,
    ) {
        this.httpClient = httpClient;
        this.dialogService = dialogService;
        this.router = router;
        this.assignService = assignService;
        this.currentUser = currentUser;
        this.controller = controllerFactory.createForCurrentScope();
    }

    async activate(params) {
        params = cleanParamsObject(params);
        this.reportFilingId = params.id;

        await this.loadDetails();

        ValidationRules.ensure((m: ReportFiling) => m.filingName)
            .required()
            .ensure((m) => m.reportingCustomerId)
            .required()
            .withMessage('goAML reporting customer ID is required.')
            .matches(/^\d+$/)
            .withMessage('goAML reporting customer ID can only contain numbers.')
            .ensure((m) => m.localCurrencyCode)
            .required()
            .ensure((m) => m.submissionDate)
            .required()
            .ensure((m) => m.agentReportingUserCode)
            .required()
            .withMessage('Reporting user code is required.')
            .ensure((m) => m.address)
            .required()
            .ensure((m) => m.city)
            .required()
            .ensure((m) => m.reason)
            .required()
            .withMessage('Reason for reporting is required.')
            .ensure((m) => m.reason)
            .maxLength(4000)
            .withMessage('Reason for reporting can be max 4000 characters.')
            .ensure((m) => m.actions)
            .required()
            .withMessage('Actions taken or planned is required.')
            .ensure((m) => m.actions)
            .maxLength(4000)
            .withMessage('Actions taken or planned can be max 4000 characters.')
            .ensure((m) => m.partyFirstName)
            .required()
            .withMessage('First name is required.')
            .ensure((m) => m.partyLastName)
            .required()
            .withMessage('Last name is required.')
            .ensure((m) => m.partyBirthdate)
            .required()
            .withMessage('Birthdate is required.')
            .ensure((m) => m.suspiciousIndicatorsValidator)
            .required()
            .withMessage('At least one suspicious indicator is required.')
            .ensure((m) => m.riskIndicatorsValidator)
            .required()
            .withMessage('At least one risk indicator is required.')
            .on(this.model);
    }

    async loadDetails() {
        const response: any = await this.httpClient.get(`report-filings/${this.reportFilingId}`);
        const json = await response.json();

        let model = <ReportFiling>json;

        if (model.submissionDateUtc) {
            model.submissionDate = dayjs(model.submissionDateUtc).utc().local().format(dateFormats.inputDateTime);
        }

        this.setReportIndicatorTypes(model);
        this.model = model;
        this.setIndicatorValidation();
        this.disableReport = model.status === ReportFilingStatus.Reported;
    }

    async downloadReport(): Promise<void> {
        this.isDownloading = true;
        this.showCloseReportError = false;
        await this.controller.validate().then(async (value) => {
            this.showDownloadError = !value.valid;
            if (value.valid) {
                this.dialogService
                    .open({
                        viewModel: DownloadReportDialog,
                        model: null,
                        lock: false,
                    })
                    .whenClosed(async (response) => {
                        if (!response.wasCancelled) {
                            this.model.status = ReportFilingStatus.Reported;
                            await this.saveReport();
                        }
                    });

                const response: any = await this.httpClient.get(`report-filings/${this.reportFilingId}/documents/zip`);
                const content = await response.blob();
                const a = window.document.createElement('a');

                a.setAttribute('style', 'display:none;');
                window.document.body.appendChild(a);
                a.download = `report-filing-${this.reportFilingId}.zip`;
                const url = URL.createObjectURL(content);
                a.href = url;
                a.target = '_blank';
                a.click();
                window.document.body.removeChild(a);

                URL.revokeObjectURL(url);
            }
            this.isDownloading = false;
        });
    }

    setReportIndicatorTypes(model: ReportFiling): void {
        if (!model.reportIndicatorTypes) {
            return;
        }

        model.reportIndicatorTypes.forEach((indicator) => {
            const existingSuspiciousIndicator = this.suspiciousIndicator.indicators.find((i) => i.name === indicator);
            if (existingSuspiciousIndicator) {
                existingSuspiciousIndicator.selected = true;
                return;
            }
            const riskIndicators = this.riskIndicators.flatMap((risk) => risk.indicators);
            const existingRiskIndicator = riskIndicators.find((i) => i.name === indicator);
            if (existingRiskIndicator) {
                existingRiskIndicator.selected = true;
            }
        });
    }

    saveReport(): void {
        this.saveWithDebounce();
    }

    async reopenReport(): Promise<void> {
        this.dialogService
            .open({ viewModel: ReopenReportDialog, model: null, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    this.model.status = ReportFilingStatus.InProgress;

                    await this.save();
                }
            });
    }

    async deleteReport(): Promise<void> {
        const model = new DeleteReportModel(this.model.reportFilingId);
        this.dialogService
            .open({ viewModel: DeleteReportDialog, model: model, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    this.router.navigateToRoute('report-filing');
                }
            });
    }

    async closeReport(): Promise<void> {
        this.showDownloadError = false;
        await this.controller.validate().then(async (value) => {
            this.showCloseReportError = !value.valid;
            if (value.valid) {
                this.model.status = ReportFilingStatus.Reported;
                await this.save(true);
            }
        });
    }

    async save(closeReport: boolean = false): Promise<void> {
        this.disableReport = this.model.status === ReportFilingStatus.Reported;

        if (!closeReport && this.disableReport) return;

        this.model.submissionDateUtc = dayjs(this.model.submissionDate, dateFormats.inputDateTime).utc().toDate();
        const reportIndicatorTypes = this.suspiciousIndicator.indicators
            .filter((indicator) => indicator.selected)
            .map((indicator) => indicator.name)
            .concat(
                this.riskIndicators
                    .flatMap((risk) => risk.indicators)
                    .filter((indicator) => indicator.selected)
                    .map((indicator) => indicator.name),
            );

        const body = json({
            ...this.model,
            reportIndicatorTypes: reportIndicatorTypes,
        });

        const response = await this.httpClient.put(`report-filings/${this.reportFilingId}`, body);
        if (response.ok) {
            this.savedDate = dayjs.utc().local().format(dateFormats.time);
            this.showSaved = true;
            window.setTimeout(() => {
                this.showSaved = false;
            }, 10000);
        }
    }

    selectAndSave(indicator: Indicator): boolean {
        if (this.disableReport) return;

        this.saveWithDebounce();
        indicator.selected = !indicator.selected;
        this.setIndicatorValidation();

        return true; // Needed for keyboard navigation
    }

    setIndicatorValidation(): void {
        this.model.suspiciousIndicatorsValidator = this.suspiciousIndicator.indicators.some(
            (indicator) => indicator.selected,
        )
            ? 'selected'
            : '';
        this.model.riskIndicatorsValidator = this.riskIndicators
            .flatMap((riskIndicator) => riskIndicator.indicators)
            .some((indicator) => indicator.selected)
            ? 'selected'
            : '';
    }

    suspiciousIndicator = {
        title: 'Suspicious indicator',
        description:
            'At least one of the two indicators of suspicion must be stated for SAR and STR. Not to be used for AIF, AIFT, RIF and RIF_T.',
        indicators: [
            { name: 'BMTTF', description: 'Suspicion of Terror financing' },
            { name: 'AMTPT', description: 'Suspicion of Money laundering' },
        ] as Indicator[],
    };

    riskIndicators = [
        {
            title: 'Geographical indicators',
            indicators: [
                {
                    name: 'GEO01',
                    description:
                        'Area or state which doesn’t have efficient systems for combating money laundering or terrorist financing.',
                },
                {
                    name: 'GEO02',
                    description: 'Area or state with considerable corruption and other relevant types of crime.',
                },
                {
                    name: 'GEO03',
                    description:
                        'An area where a state finances or supports terrorist activities or where terrorist organizations operate.',
                },
                { name: 'GEO04', description: 'Other geographical area.' },
            ] as Indicator[],
        },
        {
            title: 'Information indicator',
            indicators: [
                {
                    name: 'INF01',
                    description: 'Course of action, modus operandi, trends, national risk assessments.',
                },
                {
                    name: 'INF02',
                    description: 'Specific information from another reporting customer or source.',
                },
                {
                    name: 'INF03',
                    description: 'Sanctions, embargo or similar actions.',
                },
                {
                    name: 'INF04',
                    description: 'Specific information from a law enforcement agency.',
                },
                { name: 'INF05', description: 'Other information.' },
                {
                    name: 'INF06',
                    description: 'Specific information about probable proceedings of a crime.',
                },
            ] as Indicator[],
        },
        {
            title: 'Distribution channel indicator',
            indicators: [
                {
                    name: 'KAN01',
                    description:
                        'Business relations or transactions are conducted at a distance, without the use of methods which can verify the client’s identity (anonymization).',
                },
                {
                    name: 'KAN02',
                    description:
                        'The payment of goods or services is done by someone unknown or someone who’s not linked to the client.',
                },
                { name: 'KAN03', description: 'Other distribution channels.' },
            ] as Indicator[],
        },
        {
            title: 'Know your customer indicator',
            indicators: [
                {
                    name: 'KDK01',
                    description: 'The client has supposedly given none or incorrect information.',
                },
                {
                    name: 'KDK02',
                    description: 'The client acts supposedly on behalf of another person.',
                },
                {
                    name: 'KDK03',
                    description:
                        'The information from the client seems unusual or too complex in relation to its business.',
                },
                {
                    name: 'KDK04',
                    description: 'The client is new or changes it’s activity which makes a great impact on the KYC.',
                },
                {
                    name: 'KDK05',
                    description:
                        'The client uses a product/service or conducts a transaction in a different way than anticipated.',
                },
                {
                    name: 'KDK06',
                    description:
                        'The client uses a product/service or conducts a transaction in a way that deviates from other clients within the same category.',
                },
                {
                    name: 'KDK07',
                    description: 'Other information about a client.',
                },
            ] as Indicator[],
        },
    ];
}
