import { css } from 'lit';

export const flexStyles = css`
    .flex {
        display: flex;
    }
    .flex-col {
        flex-direction: column;
    }
    .wrap {
        flex-wrap: wrap;
    }
    .flex-1 {
        flex: 1;
    }
    .flex-2 {
        flex: 2;
    }
    .flex-vertical {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .items-center {
        align-items: center;
    }

    .items-start {
        align-items: start;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-end {
        justify-content: flex-end;
    }

    .justify-between {
        justify-content: space-between;
    }
`;
