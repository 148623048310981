import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';

export class Model {
    id: string;

    constructor(id: string) {
        this.id = id;
    }
}

@autoinject
export class DeleteReportDialog {
    controller: DialogController;
    model: Model;
    httpClient: HttpClient;

    constructor(controller: DialogController, httpClient: HttpClient) {
        this.controller = controller;
        this.httpClient = httpClient;
    }

    activate(model: Model): void {
        this.model = model;
    }

    async ok(): Promise<void> {
        await this.httpClient.delete(`report-filings/${this.model.id}`);

        this.controller.ok();
    }
}
