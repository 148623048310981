import { TemplateResult, css, html } from 'lit';
import { defineHeaderItems } from 'pli/pli-table';
import { dateTimeFormatter } from 'utils/datetime-formatter';
import { nameFormatter } from 'utils/name-formatter';
import { ReportFilingFormSection } from '../mixin';
import { ReportFilingDetailsDocument } from '../schema';

import '../../../pli/pli-button';
import '../../../pli/pli-user-bubble';
import '../../../pli/pli-drag-drop';

import { customElement } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { styles } from 'pli/styles';
import { downloadContent } from 'utils/download-content';
import { consume } from '@lit/context';
import { ConfirmService, confirmServiceContext } from 'context/confirm-service-context';
import { FileUpdateEvent } from '../../../pli/pli-drag-drop';
import { UserModel } from 'user-model';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { ErrorService, errorServiceContext } from 'context/error-service-context';

@customElement('report-filing-details-documents')
class ReportFilingDetailsDocuments extends ReportFilingFormSection {
    static styles = [
        styles.grid,
        styles.flex,
        css`
            .empty-state {
                padding: var(--size-3) 0;
            }
        `,
    ];

    @consume({ context: confirmServiceContext })
    confirmService: ConfirmService;

    @consume({ context: errorServiceContext })
    errorService: ErrorService;

    @consume({ context: currentUserContext })
    currentUser: CurrentUser;

    _profile: UserModel = new UserModel();

    async connectedCallback() {
        super.connectedCallback();
        this._profile = await this.currentUser.get();
    }

    get documentAPIBaseUrl() {
        return `report-filings/${this.payload?.reportFilingId}/documents`;
    }

    removeDocument = async (document: ReportFilingDetailsDocument) => {
        this.confirmService.confirm(
            'Deleting document',
            `Are you sure you want to delete the file: ${document.name}. This action cannot be undone.`,
            async () => {
                await this.client.delete(`${this.documentAPIBaseUrl}/${document.documentId}`, '{}');
                const updatedDocuments = this.payload?.documents.filter((d) => document.documentId !== d.documentId);
                this.setPayload({ documents: [...updatedDocuments] });
            },
        );
    };

    downloadDocument = async (document: ReportFilingDetailsDocument) => {
        const response: any = await this.client.get(`${this.documentAPIBaseUrl}/${document.documentId}`);
        const content = await response.blob();
        downloadContent(content, document.name);
    };

    onFilesUpdate = async (event: FileUpdateEvent) => {
        const { value } = event.detail;
        for (const file of value) {
            let form = new FormData();
            form.append('file', file);

            const response = await this.client.post(this.documentAPIBaseUrl, form);
            const data = await response.json();

            const { type, name } = file;
            const addedDocument: ReportFilingDetailsDocument = {
                contentType: type,
                name,
                documentId: data.documentId,
                uploadedBy: {
                    firstName: this._profile.firstName,
                    fullName: this._profile.firstName,
                    lastName: this._profile.lastName,
                    userId: this._profile.userId,
                },
                uploadedById: this._profile.userId,
                uploadedTime: new Date().toDateString(),
            };

            const documentsUpdated = [...this.payload.documents, addedDocument];
            this.setPayload({
                documents: [...documentsUpdated],
            });
        }
    };

    onFilesFail = (event: FileUpdateEvent) => {
        const { maximumFileSize } = event.detail;
        this.errorService.showError(
            'File size limit reached',
            `Attached file(s) exceeds our file size limit (${maximumFileSize} MB), please try to reduce the size of your file or try a different file. `,
        );
    };

    render() {
        const { isFormEnabled, payload, onFilesUpdate, onFilesFail, downloadDocument, removeDocument } = this;
        const headerItems = defineHeaderItems({
            Name: {
                sortField: 'Name',
                columnSpan: 3,
            },
            Date: {
                sortField: null,
                columnSpan: 3,
            },
            'Created by': {
                sortField: null,
                columnSpan: 3,
            },
            '': {
                sortField: null,
                columnSpan: 3,
            },
        });

        const renderItem = (item: ReportFilingDetailsDocument): TemplateResult =>
            html` <tr>
                <td>
                    <pli-button variant="text" .onClick="${() => downloadDocument(item)}">
                        <pli-icon slot="icon-left" name="file-earmark-image"></pli-icon>
                        ${item.name}
                    </pli-button>
                </td>
                <td>${dateTimeFormatter(item.uploadedTime)}</td>
                <td>
                    <div class="flex items-center gap-1">
                        <div>
                            <pli-user-bubble
                                userId="${item.uploadedBy.userId}"
                                firstName="${item.uploadedBy.firstName}"
                                lastName="${item.uploadedBy.lastName}"
                            ></pli-user-bubble>
                        </div>
                        <pli-text
                            >${nameFormatter({
                                firstName: item.uploadedBy.firstName,
                                lastName: item.uploadedBy.lastName,
                                userId: item.uploadedBy.userId,
                            })}</pli-text
                        >
                    </div>
                </td>
                <td>
                    <div class="flex justify-end items-center">
                        <pli-button variant="destructive" size="lg" .onClick="${() => removeDocument(item)}"
                            ><pli-icon slot="icon-left" name="trash"></pli-icon>Remove</pli-button
                        >
                    </div>
                </td>
            </tr>`;

        return html` <div>
            <div class="grid-vertical gap-2">
                ${when(
                    Boolean(payload?.documents.length),
                    () =>
                        html` <pli-table
                            .headerItems="${headerItems}"
                            .items="${payload?.documents}"
                            .renderTemplate="${renderItem}"
                        ></pli-table>`,
                    () => html`
                        <div class="grid empty-state">
                            <div class="col-start-4 col-span-6">
                                <pli-text as="p" variant="h4">No files or documents yet</pli-text>
                                <pli-text as="p"
                                    >However, its very easy to add new ones (Maximum file size: 10 MB)
                                </pli-text>
                            </div>
                        </div>
                    `,
                )}
                ${isFormEnabled
                    ? html`
                          <pli-drag-drop @filesUpdate="${onFilesUpdate}" @filesFail="${onFilesFail}"></pli-drag-drop>
                      `
                    : null}
            </div>
        </div>`;
    }
}
