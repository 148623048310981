import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class ReopenReportDialog {
    controller: DialogController;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    async ok() {
        this.controller.ok();
    }
}
