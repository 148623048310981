import { css, html } from 'lit';
import { ReportFilingFormSection } from '../mixin';
import './shared/report-filing-details-form-section';
import '../../../pli/pli-textarea';
import { styles } from 'pli/styles';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { customElement } from 'lit/decorators.js';

@customElement('report-filing-details-activity')
class ReportFilingDetailsActivity extends ReportFilingFormSection {
    static styles = [styles.grid, css``];
    render() {
        const { payload, setPayload, renderErrorMessageForField, getStateForField } = this;
        return html` <report-filings-details-form-section heading="Activity">
            <div class="grid-vertical gap-1">
                <pli-text as="p"
                    >An activity represent an event where a list of parties and goods are related directly to the report
                    without the need of a transaction.
                </pli-text>
                <pli-text variant="h4">Reporting party information </pli-text>
                <div class="grid gap-1">
                    <div class="col-span-4">
                        <label>
                            <pli-text><strong>First name*</strong></pli-text>
                        </label>
                        <pli-input
                            value="${payload?.partyFirstName}"
                            state="${getStateForField('partyFirstName')}"
                            @change="${(event: PliInputChangeEvent) =>
                                setPayload({
                                    partyFirstName: event.detail.value,
                                })}"
                        ></pli-input>
                    </div>
                    <div class="col-span-4">
                        <label>
                            <pli-text><strong>Last name*</strong></pli-text>
                        </label>
                        <pli-input
                            value="${payload?.partyLastName}"
                            state="${getStateForField('partyLastName')}"
                            @change="${(event: PliInputChangeEvent) =>
                                setPayload({
                                    partyLastName: event.detail.value,
                                })}"
                        ></pli-input>
                    </div>
                    <div class="col-span-4">
                        <label>
                            <pli-text><strong>Birthdate*</strong></pli-text>
                        </label>
                        <pli-input
                            type="date"
                            value="${payload?.partyBirthdate}"
                            state="${getStateForField('partyBirthdate')}"
                            @change="${(event: PliInputChangeEvent) =>
                                setPayload({
                                    partyBirthdate: event.detail.value,
                                })}"
                        ></pli-input>
                    </div>
                    <div class="col-span-12">
                        <div>${renderErrorMessageForField('partyFirstName')}</div>
                        <div>${renderErrorMessageForField('partyLastName')}</div>
                        <div>${renderErrorMessageForField('partyBirthdate')}</div>
                    </div>
                </div>
            </div>
        </report-filings-details-form-section>`;
    }
}
