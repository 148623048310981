// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-filing-details label {
  font-weight: bold;
  margin-top: 0.75rem;
}
.report-filing-details label span,
.report-filing-details label i {
  display: block;
  font-weight: normal;
}
.report-filing-details label input,
.report-filing-details label select,
.report-filing-details label i {
  margin-top: 0.25rem;
}
.report-filing-details .saved-wrapper {
  margin-top: -1.2rem;
}
.report-filing-details .saved {
  opacity: 0;
  transition: opacity 0.4s linear;
}
.report-filing-details .saved--show {
  opacity: 1;
  transition: opacity 0.4s linear;
}`, "",{"version":3,"sources":["webpack://./src/report-filing/details/report-filing-details.scss","webpack://./src/css/_sizes.scss"],"names":[],"mappings":"AAII;EACI,iBAAA;EACA,mBCCC;ADJT;AAIQ;;EAEI,cAAA;EACA,mBAAA;AAFZ;AAIQ;;;EAGI,mBCVH;ADQT;AAKI;EACI,mBAAA;AAHR;AAKI;EACI,UAAA;EACA,+BAAA;AAHR;AAKI;EACI,UAAA;EACA,+BAAA;AAHR","sourcesContent":["@import '/src/css/sizes';\n@import '/src/css/colors';\n\n.report-filing-details {\n    label {\n        font-weight: bold;\n        margin-top: $size-3;\n        span,\n        i {\n            display: block;\n            font-weight: normal;\n        }\n        input,\n        select,\n        i {\n            margin-top: $size-1;\n        }\n    }\n    .saved-wrapper {\n        margin-top: calc($size-4 * -1);\n    }\n    .saved {\n        opacity: 0;\n        transition: opacity 0.4s linear;\n    }\n    .saved--show {\n        opacity: 1;\n        transition: opacity 0.4s linear;\n    }\n}\n","$font-size-root: 14px;\n\n$size-base: 1rem; // 14\n\n$size-0: 0.125rem; // 1,75\n$size-1: 0.25rem; // 3,5\n$size-2: 0.5rem; // 7\n$size-3: 0.75rem; // 10,5\n$size-4: 1.2rem; // 16.8\n$size-5: 1.5rem; // 21\n$size-6: 2rem; // 28\n$size-7: 2.5rem; //35\n$size-8: 3.5rem; // 49\n$size-9: 5rem; // 70\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
