import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { getFormattedDateInputTime, getFormattedInUtcMode } from 'utils/datetime-formatter';
import { currencyEnum, reportFilingStatus } from '../schema';
import '../../../pli/pli-status-label';
import './shared/report-filing-details-form-section';
import '../../../pli/pli-input';
import '../../../pli/pli-textarea';
import '../../../pli/pli-select';
import { styles } from 'pli/styles';
import './shared/report-filing-details-form-error-message';
import { ReportFilingFormSection } from '../mixin';

@customElement('report-filing-details-general-information')
class ReportFilingDetailsGeneralInformation extends ReportFilingFormSection {
    static styles = [styles.grid, styles.flex, css``];

    render() {
        const { payload, setPayload, getStateForField, renderErrorMessageForField } = this;
        const availableCurrencies = currencyEnum.options;

        return html`<report-filings-details-form-section heading="General report information">
            ${payload?.status === reportFilingStatus.Enum.Reported
                ? html`<pli-status-label variant="Reported" slot="status-label"></pli-status-label>`
                : null}
            <div class="grid-vertical gap-05">
                <label>
                    <pli-text><strong>Name*</strong></pli-text>
                </label>
                <pli-input
                    value="${payload?.filingName}"
                    state="${getStateForField('filingName')}"
                    @change="${(event: PliInputChangeEvent) => setPayload({ filingName: event.detail.value })}"
                ></pli-input>
                ${renderErrorMessageForField('filingName')}
            </div>
            <div class="grid-vertical gap-05">
                <label>
                    <pli-text><strong>Description</strong></pli-text>
                </label>
                <pli-textarea
                    value="${payload?.description}"
                    @change="${(event: PliInputChangeEvent) => setPayload({ description: event.detail.value })}"
                ></pli-textarea>
            </div>
            <div class="grid gap-1">
                <div class="col-span-3 grid-vertical gap-05">
                    <label>
                        <pli-text><strong>goAML reporting customer ID*</strong></pli-text>
                    </label>
                    <pli-input
                        value="${payload?.reportingCustomerId}"
                        state="${getStateForField('reportingCustomerId')}"
                        @change="${(event: PliInputChangeEvent) =>
                            setPayload({ reportingCustomerId: Number(event.detail.value) })}"
                    ></pli-input>
                </div>
                <div class="col-span-3 grid-vertical gap-05">
                    <label>
                        <pli-text><strong>Local currency code*</strong></pli-text>
                    </label>
                    <pli-select
                        state="${getStateForField('localCurrencyCode')}"
                        placeholder="Select local currency code"
                        .options="${availableCurrencies.map((currency) => ({ value: currency, label: currency }))}"
                        value="${payload?.localCurrencyCode}"
                        size="md"
                        @change="${(event: CustomEvent) => setPayload({ localCurrencyCode: event.detail.value })}"
                    ></pli-select>
                </div>
                <div class="col-span-3 grid-vertical gap-05">
                    <label>
                        <pli-text><strong>Submission date*</strong></pli-text>
                    </label>
                    <pli-input
                        type="date"
                        state="${getStateForField('submissionDateUtc')}"
                        value="${getFormattedDateInputTime(new Date(payload?.submissionDateUtc))}"
                        @change="${(event: PliInputChangeEvent) =>
                            setPayload({
                                submissionDateUtc: getFormattedInUtcMode(new Date(event.detail.value)),
                            })}"
                    ></pli-input>
                </div>
                <div class="col-span-12">
                    ${renderErrorMessageForField('reportingCustomerId')}
                    ${renderErrorMessageForField('localCurrencyCode')}
                    ${renderErrorMessageForField('submissionDateUtc')}
                </div>
            </div>
        </report-filings-details-form-section>`;
    }
}
