// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `report-filings-status-marker .badge.in-progress {
  background-color: #e9ecef;
  color: #495057;
}
report-filings-status-marker .badge.reported {
  background-color: #9ec5fe;
  color: #084298;
}
report-filings-status-marker .badge.cancelled {
  background-color: #efadce;
  color: #801f4f;
}`, "",{"version":3,"sources":["webpack://./src/report-filing/report-filings-status-marker/report-filings-status-marker.scss","webpack://./src/css/_colors.scss"],"names":[],"mappings":"AAGI;EACI,yBCcW;EDbX,cCcW;ADhBnB;AAKI;EACI,yBCWW;EDVX,cCWY;ADdpB;AAMI;EACI,yBCNK;EDOL,cCNO;ADEf","sourcesContent":["@import '/src/css/colors';\n\nreport-filings-status-marker {\n    .badge.in-progress {\n        background-color: $color-light-gray;\n        color: $color-space-gray;\n    }\n\n    .badge.reported {\n        background-color: $color-jordy-blue;\n        color: $color-marian-blue;\n    }\n\n    .badge.cancelled {\n        background-color: $color-pink;\n        color: $color-murrey;\n    }\n}\n","$color-off-black: #212529;\n$color-blue: #3b72ff;\n$color-oxford-blue: #112956;\n$color-yin-mn-blue: #314b7a;\n$color-gray: #d4d4d4;\n$color-seasalt: #f9f9f9;\n$color-white: #fff;\n$color-rojo-red: #dc2626;\n$color-pink: #efadce;\n$color-murrey: #801f4f;\n$color-non-photo-blue: #9eeaf9;\n$color-cerulean: #087990;\n$color-mauve: #c29ffa;\n$color-indigo: #3d0a91;\n$color-lime-green: #f0fc62;\n$color-dark-moss-green: #626723;\n$color-peach: #fecba1;\n$color-browange: #984c0c;\n$color-light-gray: #e9ecef;\n$color-space-gray: #495057;\n$color-jordy-blue: #9ec5fe;\n$color-marian-blue: #084298;\n$color-onyx: #343a40;\n$color-seasalt: #f8f9fa;\n$color-misty-rose: #f8d7da;\n$color-burgundy: #842029;\n$color-blue-ice: #eff6ff;\n$color-mint-green: #d2f4ea;\n$color-brunswick-green: #0d503c;\n$color-office-green: #277414;\n$color-jungle-green: #1aa179;\n$color-tang-blue: #0a58ca;\n$color-periwinkle: #d1d3fd;\n$color-delft-blue: #343174;\n$color-hollywood-cerise: #e9059c;\n\n$primary: $color-oxford-blue;\n$link-color: $color-blue;\n$link-hover-color: $color-oxford-blue;\n$info: $color-blue;\n\n$color-border: #ced4da;\n\n$border-solid: 1px solid $color-border;\n$border-dashed: 1px dashed $color-border;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
