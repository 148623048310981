import { alertStatusKeys } from '../../domain/status/alert-status';
import { caseStatusKeys } from '../../domain/status/case-status';
import { reportStatus } from '../../domain/status/report-status';
import { ruleStatusKeys } from '../../domain/status/rule-status';

export const availableStatusKeys = [...ruleStatusKeys, ...caseStatusKeys, ...alertStatusKeys, ...reportStatus] as const;

export type StatusKey = (typeof availableStatusKeys)[number];

export const availableStatuses: Record<StatusKey, { color: string; text: string }> = {
    Live: {
        color: 'live',
        text: 'Live',
    },
    Editing: {
        color: 'editing',
        text: 'Editing',
    },
    Preview: {
        color: 'preview',
        text: 'Preview',
    },
    Paused: {
        color: 'paused',
        text: 'Paused',
    },
    New: {
        color: 'new',
        text: 'New',
    },
    Opened: {
        color: 'opened',
        text: 'Opened',
    },
    Escalated: {
        color: 'escalated',
        text: 'Escalated',
    },
    Closed: {
        color: 'closed',
        text: 'Closed',
    },
    FalsePositive: {
        color: 'false-positive',
        text: 'False Positive',
    },
    Investigating: {
        color: 'investigating',
        text: 'Investigating',
    },
    Resolved: {
        color: 'resolved',
        text: 'Resolved',
    },
    Reporting: {
        color: 'reporting',
        text: 'Reporting',
    },
    Reported: {
        color: 'reported',
        text: 'Reported',
    },
    InProgress: {
        color: 'in-progress',
        text: 'In Progress',
    },
    Cancelled: {
        color: 'cancelled',
        text: 'Cancelled',
    },
};

export const getStatus = (key: StatusKey) => {
    const className = `${availableStatuses[key].color}`;
    const statusText = availableStatuses[key].text;
    return { className, statusText };
};

export const getClassNameForStatus = (key: StatusKey) => `${availableStatuses[key].color}`;
