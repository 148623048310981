import './list.scss';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { IPagedController } from 'components/pager/pager';

@autoinject
export class RuleList implements IPagedController {
    httpClient: HttpClient;
    jobs: Job[] = [];
    interval: any;
    rules: Rule[] = [new Rule('Large transaction', 'Transactions exceeding a certain threshold.', 'large')];
    page: number = 1;
    of: number;
    total: number;
    me: RuleList;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
        this.me = this;
    }

    async loadPage(page: number = 1) {
        const response: any = await this.httpClient.get(`rules?page=${encodeURIComponent(`{ "no": ${page}}`)}`);
        const json = await response.json();

        this.jobs = <Job[]>json.list.map((x) => {
            return x;
        });
        this.of = Math.ceil(json.total / 15);
        this.total = json.total;
    }

    async changePage(page: number) {
        await this.loadPage(page);
    }

    async activate() {
        await this.loadPage();
    }

    async refresh() {
        await this.loadPage();
    }

    detached() {
        clearInterval(this.interval);
    }

    getFalsePositivePercentage(alerts: number, falsePositive: number): string {
        if (alerts === 0 || falsePositive === 0)
        {
            return "0";
        }
        const percentage = (falsePositive / alerts);
        return (percentage * 100).toFixed(1);
    }
}

class Job {
    id: string;
    name: string;
    percentage: number;
    running: boolean;
    created: Date;
    alerts: number;
    falsePositive: number;
}

class Rule {
    constructor(
        public name: string,
        public description: string,
        public type: string,
    ) {}
}
