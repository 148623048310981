import { consume } from '@lit/context';
import { LitElement, TemplateResult, html } from 'lit';
import { Issue, reportFilingIssuesContext } from '../context/report-filings-issues-context';
import { ReportFilingsDetailPayload, SchemaKey, reportFilingStatus } from '../schema';
import { PliInputProps } from 'pli/pli-input';
import {
    reportFilingPayloadContext,
    reportFilingPayloadMutationContext,
    PayloadMutation,
} from '../context/report-filings-payload-context';
import '../components/shared/report-filing-details-form-error-message';

import { HttpClient, clientContext } from 'context/client-context';

type Constructor<T = {}> = new (...args: any[]) => T;

export declare class ReportFilingFormSectionInterface {
    payload: ReportFilingsDetailPayload;
    issues: Issue[];
    isFormEnabled: boolean;
    client: HttpClient;
    isInvalidField(key: SchemaKey): boolean;
    setPayload: PayloadMutation;
    getStateForField(key: SchemaKey): PliInputProps['state'];
    renderErrorMessageForField(key: SchemaKey): TemplateResult | null;
}

export const ReportFilingFormSectionMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    class MixinClass extends superClass {
        @consume({ context: reportFilingIssuesContext, subscribe: true })
        issues: Issue[];

        @consume({ context: clientContext })
        client: HttpClient;

        @consume({ context: reportFilingPayloadContext, subscribe: true })
        payload: ReportFilingsDetailPayload;

        @consume({ context: reportFilingPayloadMutationContext, subscribe: true })
        setPayload: PayloadMutation;

        getStateForField = (key: SchemaKey): PliInputProps['state'] => {
            const issue = this._getIssueByField(key);
            return Boolean(issue) ? 'invalid' : 'initial';
        };

        isInvalidField = (key: SchemaKey): boolean => Boolean(this.issues?.find((i) => i.name === key));

        renderErrorMessageForField = (key: SchemaKey): TemplateResult | null => {
            const issue = this._getIssueByField(key);
            return Boolean(issue)
                ? html`<report-filing-details-form-error-message
                      >${issue.message}</report-filing-details-form-error-message
                  >`
                : null;
        };

        get isFormEnabled() {
            return this.payload?.status === reportFilingStatus.Enum.InProgress;
        }

        private _getIssueByField = (key: SchemaKey) => this.issues?.find((issue) => issue.name === key);
    }

    return MixinClass as Constructor<ReportFilingFormSectionInterface> & T;
};

export const ReportFilingFormSection = ReportFilingFormSectionMixin(LitElement);
